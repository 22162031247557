import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 33;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>コミュニケーション脳とパソコン脳の違い</li>
        <li>いきなり違う話がくるとついていけない</li>
        <li>今日会話できないわ</li>
        <li>あの、あの、あの病気</li>
        <li>コミュニケーションモードの切り替え</li>
        <li>日本語でも英語でもコミュニケーションできない</li>
        <li>しゃべれるときとしゃべれないときの波</li>
        <li>プライベートでも喋り続けるさんまさん</li>
        <li>屋根裏トークはオン？オフ？</li>
        <li>調子に乗ったがためにしゃべれなくなる人</li>
        <li>毎日会社で流れるJ-WAVEの番組</li>
        <li>ピストン西沢さんのしゃべり方講座</li>
        <li>気合いが空回りするタイプ</li>
        <li>雑談って一番難しくない？</li>
        <li>指針がないと進めることが難しい</li>
        <li>雑談、雑学はセンス？</li>
        <li>アーティストとデザイナーは違う</li>
        <li>罵詈雑言</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
